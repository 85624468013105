import React from 'react';
import { graphql } from 'gatsby';
import Row from 'react-bootstrap/Row';
import '../css/Main.css';
import '../css/OutsideBroadcast.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import TitleSection from '../components/home/TitleSection';
import ServiceSection from '../components/services/ServiceSection';
import MainLayout from '../components/MainLayout';

/**
 * Add-on Services page
 */
class AddOnServices extends React.Component {

	addBrToTitle(title){
		return title.replace(/(?:\r\n|\r|\n)/g, '<br />');
	}

	render() {

		let titleSectionData = this.props.data.allKontentItemServices.nodes[0].elements;
   		let servicesListData = this.props.data.allKontentItemServices.nodes[0].elements.services.value;
    
		return (
			<MainLayout activeItem={3} scrollSnap={false} className="outsidebroadcast-container" footerClass="footer-dark">
        {/* Title Section */}
				<Row className="nearly-fullheight fullheight">
					<TitleSection
						background={titleSectionData.title_background.value[0].url}
						title={titleSectionData.title.value}
						text={titleSectionData.subtitle.value}
						lineColor="emg-blue"
					/>
				</Row>
        {/* Service Sections */}
				{servicesListData.map((item, index) => (
					<Row className="fullheight" key={'services-service-' + index} id={item.elements.id.value}>
						<ServiceSection
							title={this.addBrToTitle(item.elements.title.value)}
							text={item.elements.description.value}
							photoUrl={item.elements.background.value[0].url}
							index={index + 1}
							reverse={(index + 1) % 2 === 0}
							link={item.elements.id.value}
							buttonColor="emg-blue"
						/>
					</Row>
				))}
			</MainLayout>
		);
	}
}
export default AddOnServices;
export const query = graphql`
	query AddOnServices {
		allKontentItemServices(filter: { system: { codename: { eq: "addonservices" } } }) {
			nodes {
				elements {
					title {
						value
					}
					subtitle {
						value
					}
					title_background {
						value {
							url
						}
					}
					services {
						value {
							... on kontent_item_service {
								elements {
									background {
										value {
											url
										}
									}
									description {
										value
									}
									title {
										value
									}
									id {
										value
									}
								}
								system {
									codename
								}
							}
							... on kontent_item_service_mobile_unit {
								elements {
									title {
										value
									}
									id {
										value
									}
									description {
										value
									}
									background {
										value {
											url
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
