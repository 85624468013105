import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../css/home/IntroSection.css";
import "../../css/services/ServiceSection.css";
import { Link } from "gatsby";

/**
 * Service section component for Services (outside broadcast and media center) pages.
 * It can be classic : text on the left, image on the right or reversed : text on the right and image on the left
 */
class ServiceSection extends React.Component {

	/**
	 * Calculates offset when component is reversed
	 * @param {int} initialOffset Offset if not reverse
	 * @param {int} span Column span
	 */
    getOffset(initialOffset, span){
        let offset = initialOffset;

        if (this.props.reverse === true){
            offset = 12 - offset - span;
        }

        return offset;

    }

	render() {
		let customBG = {
			backgroundImage: "url(" + this.props.photoUrl + ")",
        };
		
        let indexStr = this.props.index;
        if (this.props.index < 10)
            indexStr = "0"+this.props.index;

        let polygon= <div className="services-darkpolygon hidden-small-version">
						<svg style={{width:"100%", height:"100%"}} viewBox="0 0 100 100" preserveAspectRatio="none">
							<polygon points="0,0 47.368,0 100,100 0,100" fill="#0d0d0e"/>
						</svg>
					</div>;

		let reverseClass = ""
        if (this.props.reverse === true){
			reverseClass = " reverse";
            polygon = <div className="reverse services-darkpolygon hidden-small-version">
							<svg style={{width:"100%", height:"100%"}} viewBox="0 0 100 100" preserveAspectRatio="none">
								<polygon points="0,100 52.632,0 100,0 100,100" fill="#0d0d0e"/>
							</svg>
						</div>
        }

        let buttonBackgroundClass= "emg-pink-background emg-button";
        if (this.props.buttonColor){
            buttonBackgroundClass = this.props.buttonColor+"-background emg-button";
        }

		return (
			<div
				className={reverseClass+" serviceSection introSection section d-flex align-items-center"}
				style={customBG}>
				<div className="services-lightfilter" />
				{polygon}
				<div className={"serviceSection-index section-index"+reverseClass}>
                    {indexStr}
				</div>
				<Container fluid className="introSection-content">
					<Row>
						<Col
							xl={{ span: 4, offset: this.getOffset(1,4) }}
							lg={{ span: 4, offset: this.getOffset(1,4) }}
							md={{ span: 7, offset: this.getOffset(1,7)}}
							xs={{ span: 10, offset: this.getOffset(1,10)}}
							className={"emg-title"+reverseClass} 
							dangerouslySetInnerHTML={{
								__html: this.props.title
							}}>
						
						</Col>
					</Row>
					<Row className="introSection-text">
						<Col
							xl={{ span: 3, offset: this.getOffset(1,3)}}
							lg={{ span: 4, offset: this.getOffset(1,4)}}
							md={{ span: 7, offset: this.getOffset(1,7)}}
							xs={{ span: 10, offset: this.getOffset(1,10)}}>
							<div
								className={"serviceSection-text emg-text"+reverseClass}
								dangerouslySetInnerHTML={{
									__html: this.props.text,
								}}
							/>
						</Col>
					</Row>
                    <Row className={"serviceSection-button-row"+reverseClass}> 
                        <Col 
							xl={{ span: 3, offset: this.getOffset(1,3)}}
							lg={{ span: 4, offset: this.getOffset(1,4)}}
							md={{ span: 7, offset: this.getOffset(1,7)}}
							xs={{ span: 10, offset: this.getOffset(1,10)}}>
							<Link to={this.props.link}>
								<button className={buttonBackgroundClass+" emg-button"}>
									<div className="emg-button-mask" />
									DISCOVER
								</button>
							</Link>
						</Col>
                    </Row>
				</Container>
			</div>
		);
	}
}

export default ServiceSection;
